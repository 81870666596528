import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

const LoginRegSwitch = React.lazy(() => import('./components/LoginRegSwitch'));
const AccountEditor = React.lazy(() => import('./acceditor/AccountEditor'));

class thandler {
	constructor() {
		this.handler = () => {
		};
		
		const loginButtons = document.querySelectorAll('.unr-btn');
		
		for (let item of loginButtons) {
			item.onclick = () => this.handler();
		}
	}
	
	onSetHandler(_hndl) {
		this.handler = _hndl;
	}
	
	onClearHandler() {
		this.handler = () => {
		};
	}
	
	consume($elm) {
		$elm.onclick = e => {
			e.preventDefault();
			e.stopPropagation();
			this.handler();
			return false;
		};
	}
}

window.ts_on_login_form_trig = new thandler();


const $conts = document.querySelectorAll('.TJS_LoginFormContainer');
for (let $cont of $conts) {
	if ($cont.dataset.onpage === 'Y') {
		window.ts_on_login_form_trig.consume($cont);
	} else {
		const root = ReactDOM.createRoot($cont);
		root.render(
			<React.StrictMode>
				<Suspense fallback={<div style={{display: 'none'}}/>}>
					<LoginRegSwitch/>
				</Suspense>
			</React.StrictMode>
		);
	}
}

const $contsEditor = document.querySelectorAll('.TS_UnifyAccountEditor_Cont');
for (let $cEditor of $contsEditor) {
	const root = ReactDOM.createRoot($cEditor);
	root.render(
		<React.StrictMode>
			<Suspense fallback={<div style={{display: 'none'}}/>}>
				<AccountEditor {...JSON.parse($cEditor.dataset.oprom)}/>
			</Suspense>
		</React.StrictMode>
	);
}

// acceditor


